<template>
  <div v-if="user" class="flex flex-1 items-center lg:justify-end justify-normal lg:h-full h-18 lg:w-auto w-full">
    <ul class="relative h-full flex items-center lg:w-auto w-full">
      <li
        class="relative bg-[#E54825] h-full flex items-center pr-5 pl-5 text-white lg:w-auto w-full"
        @mouseenter="openUserDropDown"
        @mouseleave="closeUserDropDown"
      >
        <a class="flex items-center cursor-pointer text-[16px] font-medium">
          <img
            v-if="user.picture"
            class="h-12 w-12 rounded-full"
            :src="user.picture"
            alt="User"
          >
          <span class="ml-2 name_user">{{ user.given_name }} {{ user.family_name }}</span>
          <svg
            class="ml-2 h-4 w-4 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </a>
        <transition name="dropdown">
          <div
            v-if="isUserDropDownOpen"
            class="absolute right-0 top-16 w-full bg-white rounded-md shadow-lg overflow-hidden z-20"
          >
            <NuxtLink class="block px-4 py-2 text-gray-800 hover:bg-gray-200" to="/api/logout" external>
              <span>Log Out</span>
            </NuxtLink>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">

const { user } = useAuth()

const isUserDropDownOpen = ref(false)

const openUserDropDown = () => {
  isUserDropDownOpen.value = true
}

const closeUserDropDown = () => {
  isUserDropDownOpen.value = false
}

</script>

<style>
.cl-avatarBox {
  width: 60px;
  height: 60px;
}
</style>
