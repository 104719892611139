<template>
  <div class="lg:flex lg:flex-row grid grid-cols-1 items-center justify-center bg-[#214162] p-5 lg:h-24 h-52 gap-x-5 gap-y-5">
    <template v-if="user">
      <div class="flex rounded-full lg:h-[60px] h-auto lg:w-auto w-full">
        <NuxtImg v-if="user.picture" :src="user.picture" class="rounded-full" />
      </div>
      <div class="lg:flex lg:flex-col grid grid-cols-1">
        <h6 class="text-white lg:text-xl text-lg font-medium flex items-center">
          {{ user.given_name +" "+ user.family_name }}
        </h6>
        <p class="text-[#1ed085] font-medium text-sm flex flex-row items-center">
          <span class="w-[12px] h-[12px] bg-[#1ed085] rounded-full flex mr-1 animate-pulse" /> Online
        </p>
      </div>
    </template>
  </div>
  <div class="flex flex-col flex-1 overflow-y-auto">
    <nav class="flex-1 px-2 py-4 lg:text-lg text-sm">
      <template v-for="item in filteredSidebarItems" :key="item.to">
        <NuxtLink :to="item.to" class="text-gray-100">
          <div class="grid grid-cols-12 items-center px-2 py-3 hover:bg-gray-700">
            <!-- Icon Column -->
            <div class="col-span-2 flex justify-center">
              <component :is="item.icon" class="h-[24px] w-[24px] text-white mr-2" />
            </div>

            <!-- Text Column -->
            <div class="col-span-10">
              <span>{{ item.text }}</span>
            </div>
          </div>
        </NuxtLink>
      </template>
    </nav>
  </div>
</template>

<script setup lang="ts">

import {
  CalendarDaysIcon,
  HomeIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
  DocumentTextIcon,
  PresentationChartBarIcon,
  BriefcaseIcon
} from '@heroicons/vue/24/outline'

const sidebarItems = [
  {
    to: '/',
    text: 'Home',
    icon: HomeIcon,
    permissions: []
  },
]

const { user } = useAuth()
const userInfoStore = useUserInfo()

if (!userInfoStore.fetched) {
  await userInfoStore.fetch()
}

const filteredSidebarItems = computed(() => {
  return sidebarItems.filter((item) => {
    return item.permissions.length === 0 || userInfoStore.hasPermission(item.permissions)
  })
})

</script>
