<template>
  <ClientOnly>
    <div class="flex h-screen bg-gray-100">
      <div
        :class="{'translate-x-0': isSidebarOpen, '-translate-x-full': !isSidebarOpen}"
        class="absolute inset-y-0 left-0 flex-col lg:w-64 w-36 bg-[#15283d] transform transition-transform duration-300"
      >
        <CommonSideBarMenu />
      </div>
      <div
        :class="{'lg:ml-64 ml-36': isSidebarOpen}"
        class="lg:flex lg:flex-col grid grid-cols-1 flex-1 overflow-y-auto transition-margin duration-300"
      >
        <div class="lg:flex lg:flex-row grid grid-cols-1 items-center h-16 bg-white border-b border-gray-200 gap-x-5">
          <div class="flex items-center h-full">
            <div
              class="flex items-center bg-[#E54825] lg:h-full h-12 px-4 text-white cursor-pointer"
              @click="toggleSidebar"
            >
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
            <CommonUserDropDown class="lg:hidden flex" />
          </div>
          <div class="flex items-center justify-center py-3 lg:h-full h-36">
            <CommonLogo />
            <span class="text-gray-500 font-bold uppercase text-center">Customer Portal</span>
          </div>
          <CommonUserDropDown class="lg:flex hidden" />
        </div>
        <slot />
      </div>
    </div>
    <CommonPageLoading v-if="userInfoStore.loading" />
  </ClientOnly>
</template>

<script setup lang="ts">
useHead({
  title: 'Canopy Portal'
})

const isSidebarOpen = ref(true)

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value
}

const userInfoStore = useUserInfo()

</script>
